<template>
  <div class="routers">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="text-md-right"
      >
        <v-btn
          v-if="can('router-create')"
          color="primary"
          class="mb-5"
          @click="showAddRouterDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Router
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card>
        <v-card-title>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <refresh-button
                :loading="isRoutersRefreshing"
                @click="refreshRouters()"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
            >
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
            <v-spacer class="d-none d-md-inline" />
          </v-row>
        </v-card-title>
        <v-expand-transition>
          <v-row
            v-if="selected.length > 0 && can('router-delete')"
            class="mb-2"
          >
            <v-col class="text-md-right">
              <v-btn
                color="error"
                class="ma-2"
                @click="showConfirmBulkDeleteDialog = true"
              >
                <v-icon
                  left
                  size="22"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
                Delete Selected
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="routers"
          :loading="isRoutersLoading"
          :server-items-length="pagination.totalItems"
          :options.sync="options"
          :footer-props="footerProps"
          show-select
          class="clickable-data-table"
          @click:row="onRowClick"
        >
          <template v-slot:item.password="{ item }">
            {{ formatPassword(item.password) }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.is_offline ? 'warning' : 'success'">
              {{ item.is_offline ? 'Offline' : 'Online' }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip
              v-if="can('router-edit')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click.stop="editRouter(item)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip
              v-if="can('router-delete')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click.stop="showDeleteRouterDialog(item.id)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-expand-transition>
    <add-router-dialog
      :show-dialog="showAddRouterDialog"
      :router="router"
      @close="
        showAddRouterDialog = false
        router = {}
      "
      @router-added="onRouterAdded"
      @router-updated="onRouterUpdated"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteRouter()"
    />
    <confirm-dialog
      :show-dialog="showConfirmBulkDeleteDialog"
      :message="'Are you sure you want to delete ' + selected.length + ' records?'"
      :is-agree-button-loading="isConfirmBulkDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmBulkDeleteDialog = false"
      @agree="deleteSelectedRouters()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiPlus,
} from '@mdi/js'
import AddRouterDialog from '@/components/dialogs/AddRouterDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    AddRouterDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
    RefreshButton,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddRouterDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmBulkDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBulkDeleteDialogButtonLoading: false,
      isRoutersRefreshing: false,
      toDelete: null,
      sortBy: ['host'],
      sortDesc: [true],
      search: '',
      pagination: {
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      selected: [],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      headers: [
        {
          text: 'Host',
          value: 'host',
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Password',
          value: 'password',
        },
        {
          text: 'Port',
          value: 'port',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        },
        {
          text: 'Station',
          value: 'station.name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      searchColumn: 'host',
      searchFilters: [
        {
          text: 'Host',
          value: 'host',
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Port',
          value: 'port',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiPlus,
      },
      router: {},
      routerId: this.$route.params.id,
      routers: [],
      isRoutersLoading: true,
      routersUrl: 'routers',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Networking',
          disabled: true,
          to: { name: 'routers' },
        },
        {
          text: 'Routers',
          disabled: false,
          to: { name: 'routers' },
        },
      ],
    }
  },
  computed: {
    refreshTable() {
      return `${this.routerUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    options: {
      handler() {
        this.getRouters()
      },
      deep: true,
    },
    refreshTable() {
      this.getRouters()
    },
    searchColumn() {
      if (this.search !== '') {
        this.getRouters()
      }
    },
  },
  mounted() {
    if (!this.can('router-edit') && !this.can('router-delete')) {
      this.removeActionsHeader()
    }
    this.getRouters()
  },
  methods: {
    ...mapActions(['refreshRouters']),
    getRouters: _.debounce(function () {
      this.isRoutersLoading = true
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options
      const sortOrder = sortDesc[0] ? 'desc' : 'asc'
      axios
        .get(
          `${this.routersUrl}?paginate=true&sortBy=${sortBy[0]}&sortDirection=${sortOrder}&search=${this.search}&searchColumn=${this.searchColumn}&page=${page}&perPage=${itemsPerPage}`,
        )
        .then(response => {
          this.routers = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isRoutersLoading = false
          this.isRoutersRefreshing = false
        })
    }, 500),
    refreshRouters() {
      this.isRoutersRefreshing = true
      this.getRouters()
    },
    editRouter(router) {
      this.router = router
      this.showAddRouterDialog = true
    },
    showDeleteRouterDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteSelectedRouters() {
      this.isConfirmBulkDeleteDialogButtonLoading = true
      const selectedIds = this.selected.map(router => router.id)

      axios
        .delete('bulk-delete/routers', { data: { ids: selectedIds } })
        .then(() => {
          this.selected = []
          this.getRouters()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showConfirmBulkDeleteDialog = false
          this.isConfirmBulkDeleteDialogButtonLoading = false
        })
    },
    deleteRouter() {
      this.isConfirmDeleteDialogButtonLoading = true
      const index = this.routers.findIndex(
        item => item.id === this.toDelete,
      )
      this.routers.splice(index, 1)
      axios
        .delete(`routers/${this.toDelete}`)
        .then(() => {
          this.getRouters()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showConfirmDeleteDialog = false
          this.isConfirmDeleteDialogButtonLoading = false
        })
    },
    formatPassword(password) {
      if (password.length <= 2) {
        return password
      }

      return password.charAt(0) + '*'.repeat(password.length - 2) + password.charAt(password.length - 1)
    },
    onRouterAdded() {
      this.refreshRouters()
    },
    onRouterUpdated(router) {
      const index = this.routers.findIndex(
        item => item.id === router.id,
      )
      this.routers.splice(index, 1, router)
    },
    onPageChange() {
      this.getRouters()
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    onRowClick(item) {
      this.$router.push({ name: 'routers.show', params: { id: item.id } })
    },
    removeActionsHeader() {
      this.headers.pop()
    },
  },
}
</script>
